<script>
import PageTitle from '@/components/PageTitle.vue';
import UpdateDeleteAction from '@/components/UpdateDeleteAction.vue';
import { BButton, BCol, BFormGroup, BFormInput, BPagination, BRow, BTable } from 'bootstrap-vue';
import vSelect from 'vue-select';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import api from '@/services/api';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { createPopper } from '@popperjs/core';
import { checkLang, sortObjectValues } from '@/util/helper';
import { isPrimitive } from '@/util/inspect';
import { formatDate } from '@/util/date';
import PasteImageFileAgent from '@/mixins/pasteImageFileAgent';

export default {
    name: 'AdsCompany',
    components: {
        PageTitle,
        UpdateDeleteAction,
        ProgressLinear,
        BTable,
        BRow,
        BCol,
        BPagination,
        BFormGroup,
        BFormInput,
        BButton,
        vSelect
    },
    mixins: [CRUDPageMixin, PasteImageFileAgent],
    data() {
        const campaign_id = this.$route.params.id;

        return {
            campaign_id,
            advertisements: [],
            merchantOptions: [],
            customAppAll: [
                {
                    id: null,
                    name: 'All'
                }
            ],
            dataForModal: {
                name: null,
                description: {
                    ru: null,
                    en: null,
                    uz: null
                },
                image_id: null,
                merchant_id: null,
                campaign_id: null,
                is_published: false,
                limit_daily: null,
                limit_total: null,
                start_date: '',
                end_date: '',
                buttons: []
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            perPageList: [10, 20, 30, 50, 100],
            loading: false,
            placement: 'top',
            fileRecords: [],
            uploadUrl: 'http://dashboard-api.1it.uz/api/uploader/image',
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            fileRecordsForUpload: [],
            imageUploaded: false
        };
    },

    computed: {
        tableHeader() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.name')
                },
                {
                    key: 'description',
                    label: this.$t('description'),
                    formatter: (description) => checkLang(description)
                },
                {
                    key: 'image',
                    label: this.$t('titles.image')
                },
                {
                    key: 'limit_daily',
                    label: this.$t('titles.limit_daily')
                },
                {
                    key: 'limit_total',
                    label: this.$t('titles.limit_total')
                },
                {
                    key: 'views_today',
                    label: this.$t('titles.views_today')
                },
                {
                    key: 'views',
                    label: this.$t('titles.views')
                },
                {
                    key: 'start_date',
                    label: this.$t('titles.start_date'),
                    formatter: (start_date) => formatDate(start_date)
                },
                {
                    key: 'end_date',
                    label: this.$t('titles.end_date'),
                    formatter: (end_date) => formatDate(end_date)
                },
                {
                    key: 'buttons',
                    label: this.$t('titles.buttons')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.is_published')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.advertisements && this.advertisements.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.loading;
        },

        hasImage() {
            if (this.updatingItem) {
                return !(this.dataForModal.image && Object.keys(this.dataForModal.image));
            } else {
                return !(this.dataForModal.image_id && Object.keys(this.dataForModal.image_id));
            }
        }
    },

    watch: {
        '$route.query': {
            handler() {
                this.pagination.current = parseInt(this.$route.query.page);
                this.pagination.perPage = parseInt(this.$route.query.limit);
                this.getAdvertisements();
            },
            deep: true
        }
    },

    async mounted() {
        this.query.page = parseInt(this.$route.query.page) || 1;
        this.query.limit = parseInt(this.$route.query.limit) || 10;

        this.loading = true;
        await this.getAdvertisements();
        await this.getMerchants();
        this.loading = false;
    },

    methods: {
        checkLang,
        changeCurrentPage(page) {
            const currentPage = this.query.page;
            this.pagination.current = currentPage;
            if (page === currentPage) return;
            if (this.pagination.current > this.pagination.totalItem) {
                this.query.page = 1;
            }
            this.replaceRouter({ ...this.query, page });
        },
        changeFetchLimit() {
            const { query } = this;
            const isNotUpdate = query.limit?.toString() === this.pagination.perPage.toString();
            if (isPrimitive(query.limit) && isNotUpdate) return;
            const localQuery = {
                ...this.query,
                page: 1
            };
            const limit = this.pagination.perPage;
            this.replaceRouter({ ...localQuery, limit });
        },

        deleteButton(index) {
            if (this.dataForModal.buttons.length > 0) {
                this.dataForModal.buttons.splice(index, 1);
            }
        },
        addButton() {
            this.dataForModal.buttons.push({
                name: {
                    ru: null,
                    en: null,
                    uz: null
                },
                url: null
            });
        },

        async getAdvertisements() {
            const body = Object.assign({}, { campaign_id: this.campaign_id }, this.query);
            const query = sortObjectValues(body);
            const { data } = await api.ads.getAdvertisements(query);
            this.advertisements = data.result;
            this.pagination = data.pagination;
        },

        async fetchAdvertisement(item) {
            const body = {
                id: item.id,
                campaign_id: this.campaign_id
            };
            const { data } = await api.ads.getOneAdvertisement(body);
            this.openModalToUpdate(data.result);
        },

        async create(data) {
            await api.ads
                .createAdvertisement(data)
                .then(async ({ data }) => {
                    this.$toast.success(this.$t('success.added'));
                    this.advertisements.unshift(data);
                    this.isOpenModal = false;
                    await this.getAdvertisements();
                })
                .catch((e) => {
                    this.$toast.error(
                        e.response?.data?.message || e.message || this.$t('error.smth')
                    );
                });
        },

        async update(data) {
            await api.ads
                .updateAdvertisement(data)
                .then(async () => {
                    this.$toast.success(this.$t('success.updated.title'));
                    this.isOpenModal = false;
                    await this.getAdvertisements();
                })
                .catch((e) => {
                    this.$toast.error(
                        e.response?.data?.message || e.message || this.$t('error.smth')
                    );
                });
        },

        async deleteAdvertisement(id, index) {
            await api.ads
                .deleteAdvertisement({ id, campaign_id: this.campaign_id })
                .then(() => {
                    this.$swal({
                        icon: 'success',
                        title: this.$t('success.deleted'),
                        customClass: {
                            confirmButton: 'btn btn-success'
                        }
                    });
                    this.advertisements.splice(index, 1);
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: this.$t('error.smth'),
                        customClass: {
                            confirmButton: 'btn btn-success'
                        }
                    });
                });
        },

        submitModal() {
            this.$refs.createUpdateValidation.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;
                    body.campaign_id = this.campaign_id;
                    body.merchant_id = body.merchant?.id;
                    // delete this.dataForModal.merchant;
                    // delete this.dataForModal.image;

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.update(body);
                        } else {
                            await this.create(body);
                        }
                    } catch (error) {
                        this.$toast.error(
                            error.response?.data?.message || error.message || this.$t('error.smth')
                        );
                    }
                }
            });
        },

        async getMerchants() {
            const params = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 10
                }
            };
            await api.coin
                .fetchCoinMerchants(params)
                .then((res) => {
                    this.merchantOptions = res.data.result;
                    this.apps = [...this.customAppAll, ...res.data.result];
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getImageId(image) {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            const formData = new FormData();
            // formData.append('type', 'ads');
            formData.append('file', image.file);

            await api.imageUploader
                .imageUpload('advertisement', formData, config)
                .then(({ data }) => {
                    this.dataForModal.image_id = data.result.id;
                    this.imageUploaded = true;
                })
                .catch((e) => {
                    this.$toast.error(
                        e.response?.data?.message || e.message || this.$t('error.smth')
                    );
                });
        },

        deleteImage() {
            this.dataForModal.image = null;
            this.dataForModal.image_id = null;
            this.fileRecordsForUpload = [];
            this.imageUploaded = false;
        },

        // IMAGE
        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i === -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
                // }
            }
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
            this.imageUploaded = false;
            this.dataForModal.image = '';
            this.dataForModal.image_id = null;
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        // for v-select position
        withPopper(dropdownList, component, { width }) {
            dropdownList.style.width = width;
            const popper = createPopper(component.$refs['toggle'], dropdownList, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1]
                        }
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                            component.$el.classList.toggle('drop-up', state.placement === 'top');
                        }
                    }
                ]
            });
            return () => popper.destroy();
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('ads') }}
            </template>
            <template v-if="hasAccess('ads', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <b-row>
            <!--  TABLE  -->
            <b-col cols="12">
                <div class="card">
                    <progress-linear v-if="loading" />
                    <b-table
                        v-else
                        :fields="tableHeader"
                        :items="advertisements"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                        <template #cell(is_published)="{ item }">
                            <feather-icon
                                :class="[
                                    'stroke-current',
                                    generateIconColorToBoolean(item.is_published)
                                ]"
                                :icon="generateIconToBoolean(item.is_published)"
                            />
                        </template>

                        <template #cell(image)="{ item }">
                            <div class="table-img">
                                <img :src="item.image.path" alt="image" />
                            </div>
                        </template>

                        <template #cell(buttons)="{ item }">
                            <div style="gap: 0.25rem; display: flex; flex-wrap: wrap">
                                <div v-for="(button, index) in item.buttons" :key="index">
                                    <b-button
                                        v-if="true"
                                        v-b-tooltip.hover.top="`${$t('button.view')}`"
                                        :to="button.url"
                                        class="btn"
                                        variant="outline-info"
                                    >
                                        {{ checkLang(button.name) }}
                                    </b-button>
                                </div>
                            </div>
                        </template>

                        <template #cell(actions)="{ item, index }">
                            <update-delete-action
                                :delete-access="{ section: 'ads', permission: 'delete' }"
                                :delete-handler="deleteAdvertisement"
                                :index="index"
                                :item="item"
                                :update-access="{ section: 'ads', permission: 'update' }"
                                @openModal="fetchAdvertisement"
                            />
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>

        <!--  PAGINATION  -->
        <b-row v-if="!(pagination.current === 1 && advertisements.length === 0)">
            <b-col cols="4">
                <div class="d-flex align-items-center">
                    <span> Per page: </span>
                    <v-select
                        v-model="pagination.perPage"
                        :calculate-position="withPopper"
                        :options="perPageList"
                        :placement="placement"
                        append-to-body
                        class="ml-2"
                        style="width: 180px"
                        @input="changeFetchLimit"
                    />
                </div>
            </b-col>
            <b-col cols="8">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                    @input="changeCurrentPage"
                />
            </b-col>
        </b-row>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="false"
            :title="$t('add.news')"
            cancel-variant="danger"
            centered
            size="xl"
            style="padding: 3rem"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateValidation">
                <b-row>
                    <!--    NAME   -->
                    <b-col cols="4">
                        <label>
                            {{ $t('titles.title') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('enter.title')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    START DATE    -->
                    <b-col cols="4">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (uz)`"
                            rules="required"
                        >
                            <label>{{ $t('titles.start_date') }}</label>
                            <b-form-datepicker
                                v-model="dataForModal.start_date"
                                class="mb-1"
                                style="z-index: 99"
                            />
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    END DATE    -->
                    <b-col cols="4">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (uz)`"
                            rules="required"
                        >
                            <label>{{ $t('titles.end_date') }}</label>
                            <b-form-datepicker
                                v-model="dataForModal.end_date"
                                :state="errors.length > 0 ? false : null"
                                class="mb-1"
                                style="z-index: 99"
                            />
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[uz]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.description') }} (uz) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} (uz)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.description.uz"
                                    :placeholder="`${$t('enter.description')} (uz)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[ru]    -->
                    <b-col cols="4">
                        <label>{{ $t('titles.description') }} (ru)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} (ru)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.description.ru"
                                    :placeholder="`${$t('enter.description')} (ru)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[en]    -->
                    <b-col cols="4">
                        <label>{{ $t('titles.description') }} (en)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} (en)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.description.en"
                                    :placeholder="`${$t('enter.description')} (en)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--     MERCHANT ID     -->
                    <b-col cols="4" style="z-index: 999">
                        <label>Merchant</label>
                        <v-select
                            v-model="dataForModal.merchant"
                            :options="merchantOptions"
                            label="name"
                        />
                    </b-col>

                    <!--    LIMIT_DAILY   -->
                    <b-col cols="3">
                        <label class="capitalize">
                            {{ $t('daily_limit') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('daily_limit')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.limit_daily"
                                    :placeholder="$t('enter.field', { name: $t('daily_limit') })"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    LIMIT_TITLE   -->
                    <b-col cols="3">
                        <label class="capitalize">
                            {{ $t('total_limit') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('total_limit')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.limit_total"
                                    :placeholder="$t('enter.field', { name: $t('total_limit') })"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    IS PUBLISHED    -->
                    <b-col class="" cols="2">
                        <label for="is_published">{{ $t('titles.is_published') }}</label>
                        <b-form-checkbox id="is_published" v-model="dataForModal.is_published" />
                    </b-col>

                    <!--     IMAGE     -->
                    <div class="w-100 mb-2">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.image')"
                            rules="required"
                        >
                            <label class="pl-1" for="">{{ $t('titles.image') }}</label>
                            <b-col
                                v-if="
                                    dataForModal.image &&
                                    typeof dataForModal.image.path === 'string'
                                "
                                cols="12"
                            >
                                <div class="bordered__image-list mb-2 p-1">
                                    <div class="bordered__image-list_item">
                                        <span
                                            class="bordered__image-list_item-close"
                                            @click="deleteImage"
                                        >
                                            X
                                        </span>
                                        <img :src="dataForModal.image.path" alt="" />
                                    </div>
                                </div>
                            </b-col>

                            <b-col v-else class="d-flex align-items-center" cols="12">
                                <b-col class="px-0" cols="9">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="$t('titles.image')"
                                        rules="required"
                                    >
                                        <VueFileAgent
                                            ref="vueFileAgent"
                                            v-model="dataForModal.image"
                                            :accept="'image/*,.zip'"
                                            :deletable="true"
                                            :errorText="{
                                                type: $t('vue_file_agent.file_type_error'),
                                                size: $t('vue_file_agent.size_type_error')
                                            }"
                                            :helpText="$t('vue_file_agent.choose_image')"
                                            :maxFiles="1"
                                            :maxSize="'10MB'"
                                            :meta="true"
                                            :multiple="false"
                                            theme="list"
                                            @beforedelete="onBeforeDelete($event)"
                                            @delete="fileDeleted($event)"
                                            @select="filesSelected($event)"
                                        ></VueFileAgent>
                                        <span class="validation__red">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                </b-col>
                                <b-col class="pr-0" cols="3">
                                    <b-button
                                        :disabled="!fileRecordsForUpload.length || imageUploaded"
                                        :variant="imageUploaded ? 'success' : 'primary'"
                                        @click="getImageId(dataForModal.image)"
                                    >
                                        <span v-if="!imageUploaded">
                                            {{ $t('vue_file_agent.upload_image') }}
                                        </span>
                                        <span v-else>{{
                                            $t('vue_file_agent.image_uploaded')
                                        }}</span>
                                    </b-button>
                                </b-col>
                            </b-col>

                            <b-col cols="12">
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </b-col>
                        </ValidationProvider>
                    </div>

                    <!--     BUTTONS     -->
                    <b-col cols="12">
                        <div class="accordion" role="tablist">
                            <b-card class="mb-1" no-body>
                                <b-card-header class="py-1 px-0" header-tag="header" role="tab">
                                    <b-button v-b-toggle.accordion-1 block variant="primary">
                                        {{ $t('titles.buttons') }}
                                        <feather-icon icon=""></feather-icon>
                                    </b-button>
                                </b-card-header>

                                <b-collapse
                                    id="accordion-1"
                                    accordion="my-accordion"
                                    class="buttons-collapse"
                                    role="tabpanel"
                                    visible
                                >
                                    <b-card
                                        v-for="(button, index) in dataForModal.buttons"
                                        :key="index"
                                    >
                                        <div class="buttons-block">
                                            <div class="buttons-content">
                                                <!--    BUTTON[UZ]    -->
                                                <b-col class="mb-1" cols="4">
                                                    <ValidationProvider
                                                        v-slot="{ errors }"
                                                        :name="`${$t('titles.button')} (uz)`"
                                                    >
                                                        <label>
                                                            {{ $t('titles.button') }} (uz)
                                                        </label>
                                                        <b-form-group>
                                                            <b-form-input
                                                                v-model="button.name.uz"
                                                                :placeholder="`${$t(
                                                                    'enter.button'
                                                                )} (uz)`"
                                                                size="lg"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">
                                                            {{ errors[0] }}
                                                        </span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <!--    BUTTON[RU]    -->
                                                <b-col class="mb-1" cols="4">
                                                    <ValidationProvider
                                                        v-slot="{ errors }"
                                                        :name="`${$t('titles.button')} (ru)`"
                                                    >
                                                        <label
                                                            >{{ $t('titles.button') }} (ru)</label
                                                        >
                                                        <b-form-group>
                                                            <b-form-input
                                                                v-model="button.name.ru"
                                                                :placeholder="`${$t(
                                                                    'enter.button'
                                                                )} (ru)`"
                                                                size="lg"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">
                                                            {{ errors[0] }}
                                                        </span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <!--    BUTTON[EN]    -->
                                                <b-col class="mb-1" cols="4">
                                                    <ValidationProvider
                                                        v-slot="{ errors }"
                                                        :name="`${$t('titles.button')} (en)`"
                                                    >
                                                        <label
                                                            >{{ $t('titles.button') }} (en)</label
                                                        >
                                                        <b-form-group>
                                                            <b-form-input
                                                                v-model="button.name.en"
                                                                :placeholder="`${$t(
                                                                    'enter.button'
                                                                )} (en)`"
                                                                size="lg"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">
                                                            {{ errors[0] }}
                                                        </span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <!--    BUTTON LINK    -->
                                                <b-col cols="12">
                                                    <ValidationProvider
                                                        v-slot="{ errors }"
                                                        :name="$t('titles.button_link')"
                                                    >
                                                        <label>{{
                                                            $t('titles.button_link')
                                                        }}</label>
                                                        <b-form-group>
                                                            <b-form-input
                                                                v-model="button.url"
                                                                :placeholder="
                                                                    $t('enter.button_link')
                                                                "
                                                                size="lg"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">
                                                            {{ errors[0] }}
                                                        </span>
                                                    </ValidationProvider>
                                                </b-col>
                                            </div>
                                            <div class="btn-action btn-delete">
                                                <b-button
                                                    class="ml-auto"
                                                    variant="danger"
                                                    @click="deleteButton(index)"
                                                >
                                                    <feather-icon icon="TrashIcon" />
                                                </b-button>
                                            </div>
                                        </div>
                                    </b-card>

                                    <div class="btn-action btn-add">
                                        <b-button
                                            class="ml-auto"
                                            variant="primary"
                                            @click="addButton"
                                        >
                                            {{ $t('button.add') }}
                                            <feather-icon icon="PlusIcon" />
                                        </b-button>
                                    </div>
                                </b-collapse>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

::v-deep .table td {
    white-space: nowrap !important;
}

::v-deep .card {
    margin-bottom: 0 !important;
}

.table-img {
    width: 60px;
    height: 60px;
    background-color: #999999;
    border-radius: 0.25rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.buttons-collapse {
    display: flex;
    flex-direction: column;

    .card {
        margin-bottom: 0 !important;
    }
}

.buttons-block {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.buttons-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.btn-action {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .btn {
        white-space: nowrap;
    }

    &.btn-add {
        margin: 1rem auto !important;
    }
}
</style>
